import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, Route, Routes } from 'react-router-dom';
import { Button} from 'react-bootstrap';
import Home from '../components/Home/Home';
import WpMenu from '../components/wpmenu/WpMenu';
import HpMenu from '../components/hpmenu/HpMenu';
import SteamRollerMenu from '../components/steamrollermenu/SteamRollerMenu';
import BDStemsMenu from '../components/bndstemsmenu/BnDStemsMenu';
import BubblersMenu from '../components/bubblersmenu/BubblersMenu';
import ChillumsMenu from '../components/chillumsmenu/ChillumsMenu';
import AshMenu from '../components/ashmenu/AshMenu';
import NectorMenu from '../components/nectormenu/NectorMenu';
import MugsMenu from '../components/mugsmenu/MugsMenu';
import SiliconeMenu from '../components/siliconemenu/SiliconeMenu';
import AddItem from '../components/addItem/AddItem';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './Navbar.css';


const Navigationbar = () => {
  const navigate = useNavigate();

//   const getAllOffers = async () => {
//     try {
//       let listOfOffers = await axios.post(`${process.env.REACT_APP_API_URL}/offer/getalloffers`);
//      // console.log(listOfOffers.data);
//       dispatch(setOffersInfo(listOfOffers.data));
//     }
//     catch (e) {
//       // setOffersList([]);
//       console.log(e, "offer error");
//       dispatch(setOffersInfo([]));
//     }

//   }

//   const getTranasctions = async () => {
//     try {
//         if(userId===null){
//             userId=JSON.parse(localStorage.getItem("user")).userId
//           }
//         await axios.get(`${process.env.REACT_APP_API_URL}/transaction/getRecentTransactions/${userId}`);
      
//         dispatch(setLiveTrack(true));
       
//     }
//     catch (e) {
     
//         dispatch(setLiveTrack(false));
//     }
// }


  useEffect(() => {
    // getAllOffers();
    // getTranasctions();
  }, []);

  return (
    <>
       {/* <Navbar collapseOnSelect className="custom-navbar" expand="lg" bg="primary" variant="dark"> 
         <Container fluid>
          <Navbar.Brand onClick={e => navigate("/")}>Price Center</Navbar.Brand> 
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">

              <Nav.Link className="custom-nav-link" as={Link} to="/home">Home</Nav.Link>

              <Nav.Link className="custom-nav-link" as={Link} to="/wpipes">Water Pipes</Nav.Link>
              <Nav.Link className="custom-nav-link" as={Link} to="/hpipes">Hand Pipes</Nav.Link>
              <Nav.Link className="custom-nav-link" as={Link} to="/bdstems">Bowls & Downstems</Nav.Link>
              <Nav.Link className="custom-nav-link" as={Link} to="/bublers">Bubblers</Nav.Link>
              <Nav.Link className="custom-nav-link" as={Link} to="/chillums">chillums</Nav.Link>
              <Nav.Link className="custom-nav-link" as={Link} to="/acatchers">Ash Catchers</Nav.Link>
              <Nav.Link className="custom-nav-link" as={Link} to="/ncollectors">Nector Collectors</Nav.Link>
              <Nav.Link className="custom-nav-link" as={Link} to="/smugs">Smoking Mugs</Nav.Link>
              
             
            </Nav>
          </Navbar.Collapse> */}
           <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <h3 style={{color: 'darkred'}}><Link to={"/home"} style={{ textDecoration: 'none', color: 'inherit' }}>Price Center</Link></h3>&nbsp;
            <li className="nav-item active">
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/wpipes"} className="nav-link">
                Water Pipes
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/hpipes"} className="nav-link">
                Hand pipes
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/steamroller"} className="nav-link">
                Steamroller
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/bdstems"} className="nav-link">
                Bowls & Downstems
              </Link>
            </li>
            <li className="nav-item">
              <Link  to={"/bublers"} className="nav-link">
                Bubblers
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/chillums"} className="nav-link">
                chillums
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/acatchers"} className="nav-link">
                Ash Catchers
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/ncollectors"} className="nav-link">
                Nectar Collectors
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/smugs"} className="nav-link">
                Smoking Mugs
              </Link>
            </li>
            <li className="nav-item">
              <Link to={"/siliconepipes"} className="nav-link">
                Silicone Pipes
              </Link>
            </li>
            
          </ul>
        </div>
        </nav>
        {/* </Container>
       </Navbar> */}

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/wpipes" element={<WpMenu />} />
        <Route path="/hpipes" element={<HpMenu />} />
        <Route path="/steamroller" element={<SteamRollerMenu />} />
        <Route path="/bdstems" element={<BDStemsMenu />} />
        <Route path="/bublers" element={<BubblersMenu />} />
        <Route path="/chillums" element={<ChillumsMenu />} />
        <Route path="/acatchers" element={<AshMenu />} />
        <Route path="/ncollectors" element={<NectorMenu />} />
        <Route path="/smugs" element={<MugsMenu />} />
        <Route path="/siliconepipes" element={<SiliconeMenu />} />
        <Route path="/addmenuitem" element={<AddItem />} />
      </ Routes>
    </>
  );
};

export default Navigationbar;