import React from 'react';
import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import Alert from 'react-bootstrap/Alert';

const AddItem = () => {

  const [validated, setValidated] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemProductId, setItemProductId] = useState("");
  const [itemCategory, setItemCategory] = useState("Water Pipes");
  const [itemSizeCategory, setItemSizeCategory] = useState("");
  const [itemSize, setItemSize] = useState("");
  const [itemImage, setItemImagePath] = useState(null);
  const [successMessage, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const API_URL = "http://localhost:1111/menu/insertItem";

  const onSubmitItem = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setIsLoading(false);
    }

    else {
      setSuccessMsg(false);
      setErrorMsg(false);
      let reqObj = {
        itemName,
        itemProductId,
        itemCategory,
        itemSizeCategory,
        itemSize,
        itemImage,
      }

      axios.post(`${process.env.REACT_APP_API_URL}/menu/insertItem`, reqObj, {
        headers: { "Content-Type": "multipart/form-data" }
      }).then(
        res => {
        
          setSuccessMsg(true);
          setIsLoading(false);
        }
      ).catch(err => {
        console.log(err);
        setErrorMsg(true);
        setIsLoading(false);
      })
    }
    setValidated(true);
  }
  useEffect(() => {if(itemCategory === "Water Pipes"){ if(itemSizeCategory===""){setItemSizeCategory("5-7")}else{setItemSizeCategory(itemSizeCategory)}}else{setItemSizeCategory("")}})

  return (
    <>
      <Container className='d-flex justify-content-center mt-3'>
        <Row>
          <Col> <h2 style={{color: 'floralwhite'}}> ADD PRODUCT </h2></Col>

        </Row>
      </Container>
      <Container className='mt-3'>
        <Form noValidate validated={validated} onSubmit={onSubmitItem}>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label style={{color: 'floralwhite'}}>Product Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Product Name"
                value={itemName}
                onChange={(e) => { setItemName(e.target.value) }}
              />
              <Form.Control.Feedback type="invalid" style={{color: 'floralwhite'}}>Item name is required</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label style={{color: 'floralwhite'}}>Product ID</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="PID"
                  aria-describedby="inputGroupPrepend"
                  required
                  value={itemProductId}
                  onChange={(e) => { setItemProductId(e.target.value) }}
                />
                <Form.Control.Feedback type="invalid" style={{color: 'floralwhite'}}>
                  Please mention id of the item
                </Form.Control.Feedback>
              </InputGroup>


            </Form.Group>
          </Row>

          <Row className='mb-3'>
            <Form.Group as={Col} md="6" controlId="validationCustom02">
              <Form.Label style={{color: 'floralwhite'}}>Product Category</Form.Label>
              {/* <Form.Control
                required
                type="text"
                placeholder="Item Category"
                value={itemCategory}
                onChange={(e) => { setItemCategory(e.target.value) }}
              /> */}
              <Form.Select aria-label="Default select example" 
                value={itemCategory}
                onChange={(e) => { setItemCategory(e.target.value) }} 
                required = {true}
                placeholder='Select Category'
                >
                <option value="Water Pipes">Water Pipes</option>
                <option value="Hand Pipes">Hand Pipes</option>
                <option value="Steamroller">Steamroller</option>
                <option value="bowls & downstems">Bowls & Downstems</option>
                <option value="Bubblers">Bubblers</option>
                <option value="Chillums">Chillums</option>
                <option value="Ash Catchers">Ash Catchers</option>
                <option value="Nector Collectors">Nector Collectors</option>
                <option value="Smoking Mugs">Smoking Mugs</option>
                <option value="Silicone Pipes">Silicone Pipes</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid" style={{color: 'floralwhite'}}>Category is required</Form.Control.Feedback>
            </Form.Group>
            {itemCategory == "Water Pipes"?
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label style={{color: 'floralwhite'}}>Product size Category</Form.Label>
            
              <Form.Select aria-label="Default select example" 
                value={itemSizeCategory}
                onChange={(e) => { setItemSizeCategory(e.target.value) }} 
                required = {true}
                placeholder='Select Size Category'
                >
                <option value="5-7">5"-7"</option>
                <option value="8-11">8"-11"</option>
                <option value="12-15">12"-15"</option>
                <option value="16-22">16"-22"</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid" style={{color: 'floralwhite'}}>Size category is required</Form.Control.Feedback>
            </Form.Group>:null
            }
          </Row>


          <Row className='mb-3'>
            <Form.Group as={Col} md="6" controlId="validationCustom03">
              <Form.Label style={{color: 'floralwhite'}}>Size</Form.Label>
              <Form.Control
                type="text"
                placeholder="Item Size"
                value={itemSize}
                onChange={(e) => { setItemSize(e.target.value) }}
              />
              {/* <Form.Control.Feedback type="invalid">Item Size is required</Form.Control.Feedback> */}
            </Form.Group>
            
          </Row>

      

          <Form.Group className="position-relative mb-3">
            <Form.Label style={{color: 'floralwhite'}}>File</Form.Label>
            <Form.Control
              type="file"
              required
              name="itemImage"

              onChange={(e) => { setItemImagePath(e.target.files[0]) }}

            />
            <Form.Control.Feedback type="invalid" tooltip style={{color: 'floralwhite'}}>
              Upload the image
            </Form.Control.Feedback>
          </Form.Group>



          <Button type='submit'  disabled={isLoading}>{isLoading ? 'Please wait..., we are adding your product' : 'ADD PRODUCT'}</Button>
        </Form>


      </Container>

      <Container className='mt-2'>
        <Row>
          <Col>
            {(successMessage === true) ? <Alert variant="info">Item is successfully added</Alert> : null}
            {(errorMsg === true) ? <Alert variant="warning">Failed to add item</Alert> : null}
          </Col>
        </Row>
      </Container>


    </>
  )
}

export default AddItem;