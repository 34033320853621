import Container from 'react-bootstrap/Container';
import {Row, Col, Form, Button} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

// need to add images
import "./Home.css"

const Home=()=>{
    let navigate = useNavigate();
    const onAddNewItem = (e) => {
        e.preventDefault();
        navigate('/addmenuitem');
    }
    const [passKey, setPassKey] = useState("");
    const [hide, setHide] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const retreiveKey = async(e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/menu/cmpKey`, {
            key: passKey
        })
            .then(results => {
                if(results.data.output===true)
                    setHide(false);
                else
                    setHide(true);
            setIsLoading(false);
            }
            )
            .catch(e => {
                setHide(true);
                setIsLoading(false);
                // setSearchItem("");
            })

    }
    return(
        <div className="home-container">
            
            <Container>
            <br></br>
            <Row calss= "mt-1">
            
                <h3>
                    <marquee behavior="scroll" direction="right" scrollamount="11" >
                       <b> <p style={{color:'white'}}>WELCOME TO PRICE CENTER</p></b></marquee>
                </h3>
                {/* <Row>
                    <Col></Col>
                    <Col> <div class="scrolling-text">
                <h3>
                    <b><p style={{color: 'white'}}>WELCOME TO PRICE CENTER</p></b>
                </h3>
                </div></Col>
                    <Col></Col>
                </Row> */}
              
            </Row>
            <Container style={{ width: '90%' }}>
            <Row calss= "mt=2">
                <Carousel style={{ width: '100%' }}>
                    <Carousel.Item interval={1500}>
                
                <img 
                    className="d-block w-100 slideimages"   
                    src={"https://cdn.shopify.com/s/files/1/0043/9507/3582/products/Skull_Glow_in_the_dark_Beaker_Bongs.jpg?v=1638606094"}

                    alt="Water pipes"
                    />
                </Carousel.Item>
                <Carousel.Item interval={1500}>
                    <img 
                    className="d-block w-100 slideimages"

                    src={"https://cdn.shopify.com/s/files/1/1439/6476/products/2231_Hand_Eeze_Assorted_2.5_Mix_Glass_Pipe_Up-N-SmokeOnlineSmokeShopOnlineHeadShop_1024x1024.jpg?v=1586973939"}

                    alt="Hand pipes"
                    />
                    </Carousel.Item>
                    <Carousel.Item interval={1500}>
                    <img 
                    className="d-block w-100 slideimages" 
                    src = {"https://cdn.shopify.com/s/files/1/2017/7131/products/accessories-wig-wag-bong-bowl-fat-buddha-glass-34735312371880.jpg?v=1666195779&width=1024"}        

                    alt="Bowls&downstems"
                    />
                    </Carousel.Item>

                <Carousel.Item interval={1500}>
                    <img 
                    className="d-block w-100 slideimages" 
                    src = {"https://cdn.shopify.com/s/files/1/0016/9938/1346/products/MG_8238-137_40ea7e1c-73f0-42f8-9fa7-4c14e77e0f98_1000x1000.jpg?v=1677858568"}        

                    alt="Bubblers"
                    />
                    </Carousel.Item>
                    <Carousel.Item interval={1500}>
                    <img 
                    className="d-block w-100 slideimages"  
                    src={"https://i0.wp.com/smokeandvapedallas.com/wp-content/uploads/2021/04/4-079-e1618667655302.jpg?fit=1568%2C1058&ssl=1"}

                    alt="Chillums"
                    />
                    </Carousel.Item>
                    <Carousel.Item interval={1500}>
                    <img 
                    className="d-block w-100 slideimages"  
                    src={"https://highereducationva.com/wordpress/wp-content/uploads/2022/06/IMG_4484-1920x1278.jpg"}

                    alt="Smoking Mugs"
                    />
                    </Carousel.Item>
            </Carousel>
            </Row>
            </Container>
            <br></br>
            <Row className='menu-center-text mt-3'>
                    <Form className="mb-3" onSubmit={(e) => { retreiveKey(e) }} >
                        <Row>
                        <Col className='col-md-2'>
                        <Form.Control
                            value={passKey}
                            type='password'
                            onChange={(e) => { setPassKey(e.target.value) }}
                            id="key-name"
                            placeholder="KEY"
                        />
                        </Col>
                        
                        <Col className='col-md-1'>
                        <Button type='submit'  disabled={isLoading}>{isLoading ? 'Loading...' : 'SUBMIT'}</Button>
                        </Col>
                        </Row>
                    </Form>
            </Row>
            {
                hide===false?
            <Row>
                    <Col>
                        <Button variant="primary" onClick={onAddNewItem}   >
                            Add new Item
                        </Button>
                    </Col>
                </Row>:null
            }
            </Container>
         
        </div>
        
        )
}

export default Home;